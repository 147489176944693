import React from 'react';
import ReactLoading from 'react-loading';

const Loading = (props) => {

  if(!props.show)
    return null

  return(
    <div>                 
      <div className={'d-flex justify-content-center m-5'}>
        <ReactLoading type={'spinningBubbles'} color={'#002d73'} height={'20%'} width={'20%'} />
      </div>
      <div className={'d-flex justify-content-center m-5'}>
        <h3 style={{textAlign: 'center'}}>{props.content}</h3>
      </div>
    </div>
  )
}

export default Loading