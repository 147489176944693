import React, { useState } from 'react';
import { Col, Row, Form, FloatingLabel } from 'react-bootstrap';
import { Button, Avatar, Card, Flex, Text, Grid, Popup } from '@fluentui/react-northstar';
import AsyncSelect from 'react-select/async';
import AsyncCreatableSelect from 'react-select/async-creatable';
import { components } from 'react-select';
import { useTranslation } from 'react-i18next';
import debounce from 'lodash.debounce';
import userImage from './images/Windows-10-user-icon-big.png';

const { ValueContainer, Placeholder } = components;

const CustomValueContainer = ({ children, ...props }) => {
  return (
    <ValueContainer {...props}>
      <Placeholder {...props} isFocused={props.isFocused}>
        {props.selectProps.placeholder}
      </Placeholder>
      {React.Children.map(children, child =>
        child && child.type !== Placeholder ? child : null
      )}
    </ValueContainer>
  );
};

const MeetingForm = (props) => {
  const { t } = useTranslation();
  const [ showMore, setShowMore ] = useState(false)

  if(!props.show)
    return null

  const styles = {
    row:{
      display: "flex"
    },
    input : {
      padding: "5px"
    },
    btn: {
      textAlign: "right"
    }
  }

  const colourStyles = {
    control: (styles) => ({ ...styles, color: '#212529', height: 'calc(3.5rem + 2px)', lineHeight: '1.25' }),
    placeholder: (styles) => ({ ...styles, color: '#212529', opacity: '.65', transform: 'scale(.85) translateY(-.5rem) translateX(.15rem)', position: 'absolute', top : '0', left: '0', paddingTop: '1rem', gridArea: '0/1/2/3' }),
    singleValue: (styles) => ({ ...styles, paddingTop: '1.625rem', paddingBottom: '.625rem' }),
    valueContainer: (styles) => ({ ...styles, height: '100%' }),
    input: (styles) => ({ ...styles, paddingTop: '1.625rem', paddingBottom: '.625rem' }),
  };

  const onChangeInput = (newInput) => {
    return newInput;
  }

  const handleOnCreateOption = (event) => {
    console.log(event)
    if(!event || event.indexOf('@') === -1 ||event.indexOf('.') === -1){
    }
    else {
      props.handleOnSelectParticipant({userPrincipalName: event, mail: event})
    }
  }

  const _loadSuggestions = (query, callback) => {
    props.options(query).then(resp => callback(resp));
  };
  
  const loadSuggestions = debounce(_loadSuggestions, 500);

  return(
    <Form style={{margin: '1.6rem'}}>
      <Row className="mb-3">
        <Form.Group as={Col} style={styles.input}>
          <FloatingLabel controlId="subject" label={t('input-label-subject')}>
            <Form.Control type="subject" onChange={props.handleOnChange} value={props.subject} isInvalid={props.errors.subject}/>
          </FloatingLabel>
        </Form.Group>
      </Row>
      <Row className="mb-3">
        <Form.Group as={Col} style={styles.input}>
          <AsyncSelect
            components={{ValueContainer: CustomValueContainer}}
            value={props.organizer}
            defaultOptions={[]}
            placeholder={t('input-label-organizer')}
            loadOptions={loadSuggestions}
            onInputChange={onChangeInput}
            isLoading={false}
            onChange={props.handleOnSelectOrganiser}
            getOptionLabel={option => `${option.mail}`}
            styles={colourStyles}
            theme={(theme) => ({
              ...theme,
              borderRadius: '.25rem',
              border: '1px solid #ced4da',
              colors: {
                ...theme.colors,
                primary: '#86b7fe',
              },
            })} 
          />
        </Form.Group>
      </Row>
      {!showMore && <Row className="mb-3 justify-content-center">
        <Button text onClick={()=>setShowMore(true)}>{t('button-label-showMore')}</Button>
      </Row>}
      {showMore && <>
      <Row className="mb-3">
        <Form.Group as={Col} style={styles.input}>
          <AsyncCreatableSelect
            components={{ValueContainer: CustomValueContainer}}
            value={null}
            defaultOptions={[]}
            placeholder={t('input-label-participants')}
            loadOptions={loadSuggestions}
            onCreateOption={handleOnCreateOption}
            onInputChange={onChangeInput}
            isLoading={false}
            onChange={props.handleOnSelectParticipant}
            getNewOptionData={(inputValue, optionLabel) => ({
                id: optionLabel,
                mail: inputValue,
                __isNew__: true
            })}
            getOptionLabel={option => `${option.mail}`}
            styles={colourStyles}
            theme={(theme) => ({
              ...theme,
              borderRadius: '.25rem',
              border: '1px solid #ced4da',
              colors: {
                ...theme.colors,
                primary: '#86b7fe',
              },
            })} 
          />
        </Form.Group>
      </Row>
      <Row className="mb-3">
      <div className="my-2" >
        <Grid columns={2}>
          {props.participants.map((element, index) =>(
            renderParticipants(index, t, props, element)
          ))}
        </Grid>
      </div>
      </Row>
      <Row className="mb-3">
        <Form.Group as={Col} style={styles.input}>
          <FloatingLabel controlId="date" label={t('input-label-date')}>
            <Form.Control type="date" onChange={props.handleOnChange} value={props.date} isInvalid={props.errors.date} />
          </FloatingLabel>
        </Form.Group>
        <Form.Group as={Col} style={styles.input}>
          <FloatingLabel controlId="startDateTime" label={t('input-label-startDateTime')}>
            <Form.Control type="time" onChange={props.handleOnChange} value={props.startDateTime} isInvalid={props.errors.startDateTime} />
          </FloatingLabel>
        </Form.Group>
        <Form.Group as={Col} style={styles.input}>
          <FloatingLabel controlId="endDateTime" label={t('input-label-endDateTime')}>
            <Form.Control type="time" onChange={props.handleOnChange} value={props.endDateTime} isInvalid={props.errors.endDateTime} />
          </FloatingLabel>
        </Form.Group>
        <Row className="mb-3 justify-content-center">
          <Button onClick={()=>setShowMore(false)} content={t('button-label-showLess')} text />
        </Row>
      </Row></>}
      <Row className="mb-3">
        <Form.Group as={Col} style={styles.input}>
          <Button onClick={props.onCreateClick} primary >{t('button-label-create')}</Button>
        </Form.Group>
      </Row>
    </Form>
  )
}

export default MeetingForm

function renderParticipants(index, t, props, element) {
  return (
    <Popup
      trigger={
        <Card key={`participant-card-#${index}`} aria-roledescription="card avatar" horizontal onClick={()=>props.handleOnRemoveParticipant(element)} >
          <Card.Column>
            <Card.Header fitted>
              <Flex gap="gap.small">
                <Avatar
                  image={userImage}
                  label="Copy bandwidth"
                  name={element.mail}
                />
                <Flex column>
                  <Text content={element.mail} weight="bold" />
                </Flex>
              </Flex>
            </Card.Header>
          </Card.Column>
        </Card>
      }
      content={t(`tooltip-content-click_to_remove`)}
      on="hover"
      pointing
      position={'above'}
    />);
}
