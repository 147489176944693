import React from 'react';
import { Segment, Button, Header } from '@fluentui/react-northstar'

const MeetingError = (props) => {

  if(!props.show)
    return null

  return(
    <Segment content="Colored segment." color="red" >
      <Header content={props.title} color='red' />
      <p>
        {props.content}
      </p>
      <hr />
      <Button onClick={()=>window.location.reload()} secondary content={props.buttonText}/>
    </Segment>
  )
}

export default MeetingError