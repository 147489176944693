import React from 'react';
import { Flex, Text, Header, Segment } from '@fluentui/react-northstar'

const MeetingLink = (props) => {
  if(!props.show)
    return null

  const styles = {
    wrapContainer: {
      wordWrap: "break-word"
    }
  }

  return(
    <Segment>
      <Header as="h1" content="Microsoft Teams Meeting" />
      <p>
        <Text size='large' content="Join on computer or via mobile app" color='grey' /><br />
        <a target={'_blank'} href={props.joinWebUrl}><span style={styles.wrapContainer}>{props.joinWebUrl}</span></a>
      </p>
      {props.videoTeleconferenceId && <p>
        <Text size='large' content="Join using a video conferencing device" color='grey' /><br />
        <Text content="Call: dachser@m.webex.com" /><br />
        <Text content={`VTC ID: ${props.videoTeleconferenceId}`} /><br />
        <Text content={`Alternative VTC dialing parameters`} /><br />
        <a target={'_blank'} href={`https://www.webex.com/msteams?confid=${props.videoTeleconferenceId}&tenantkey=dachser&domain=m.webex.com`}><span style={styles.wrapContainer}>{`https://www.webex.com/msteams?confid=${props.videoTeleconferenceId}&tenantkey=dachser&domain=m.webex.com`}</span></a><br />
      </p>}
      {props.tollNumber && <Text size='large' color='grey'>Or call (audio only)</Text>}
      {props.tollNumber && (<a target={'_blank'} href={`tel:${props.tollNumber}`}>{props.tollNumber}</a>)}
      {props.conferenceId && (<Text>Conference call ID: {props.conferenceId}</Text>)}
      {props.dialinUrl && <a target={'_blank'} href={`${props.dialinUrl}`}>Search local number</a>}
    </Segment>
  )
}

export default MeetingLink