import axios from 'axios';

export const getActiveDirectoryUsers = (search) => {
  if(!search)
    return []
  
  var auth = new Buffer.from(window.__RUNTIME_CONFIG__.REACT_APP_API_USER + ":" + window.__RUNTIME_CONFIG__.REACT_APP_API_PASSWORD).toString("base64")
  var url = `${window.__RUNTIME_CONFIG__.REACT_APP_API_URL}/api/${window.__RUNTIME_CONFIG__.REACT_APP_API_VERSION}/users?mail=${search}`
  return new Promise((resolve, reject) => {
    var config = {
      method: 'get',
      url: url,
      headers: { 
        'Content-Type': 'application/json',
        Authorization: 'Basic ' + auth,
      }
    };

    axios(config)
    .then(function ({data}) {
      resolve(data.data);
    })
    .catch(function (error) {
      resolve();
    });
  });
}

export const createOnlineMeeting = async (meeting) => {
  try{
    var auth = new Buffer.from(window.__RUNTIME_CONFIG__.REACT_APP_API_USER + ":" + window.__RUNTIME_CONFIG__.REACT_APP_API_PASSWORD).toString("base64")
    var url = `${window.__RUNTIME_CONFIG__.REACT_APP_API_URL}/api/${window.__RUNTIME_CONFIG__.REACT_APP_API_VERSION}/meetings`
    var data = JSON.stringify({
      "subject": meeting.subject,
      "date": meeting.date,
      "startDateTime": meeting.startDateTime,
      "endDateTime": meeting.endDateTime,
      "participants": meeting.participants,
      "organizer": meeting.organizer
    });

    var config = {
      method: 'post',
      url: url,
      headers: { 
        Authorization: 'Basic ' + auth,
        'Content-Type': 'application/json'
      },
      data : data
    };
    var result = await axios(config).catch(err => {
      if (err.response.status === 500) {
        throw new Error(`createOnlineMeeting`);
      }
      throw err;
    });
    return result.data
  }
  catch (err) {
    return err
  }
}