import React, { useEffect, useState } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { Provider, teamsTheme } from '@fluentui/react-northstar';
import * as msTeams from '@microsoft/teams-js';
import { Frame } from './components';
import i18n from './i18n'

function App() {
  const [ context, setContext ] = useState()
  useEffect(()=>{
    new Promise((resolve) => {
      msTeams.initialize()
      msTeams.getContext(resolve);
    }).then((context) => {
      setContext(context)
      try{
        let language = context.locale.split(/-|_/)[0]
        i18n.changeLanguage(language)
      }
      catch {
        i18n.changeLanguage('en')
      }
    })
  },[])

  if(!context)
    return null

  return (
    <BrowserRouter >
      <Switch>
        <Route path='/'>
          <Provider theme={teamsTheme}>
            <Frame context={context} />
          </Provider>
        </Route>
      </Switch>
    </BrowserRouter>
  );
}

export default App;